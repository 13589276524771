import { Typography } from '@mui/material'
import { useRouter } from 'next/navigation'
import { appDayjs, toAsPath } from 'shared'

import { pagesPath } from '@utils/$path'

import { useClinicAlias } from '@hooks/useClinicAlias'

export const AppLogo = (): JSX.Element => {
  const router = useRouter()
  const clinicAlias = useClinicAlias()

  const handleReloadOrPushToTop = () => {
    const today = appDayjs().format('YYYY-MM-DD')

    const pathname = window.location.pathname

    // origin/[clinicAlias]/reservations/[date]
    const paths = pathname.split('/')
    const isTopPage = paths[2] === 'reservations' && paths.length === 4
    const isToday = paths[3] === today

    const redirectToToday = () =>
      router.push(
        toAsPath(
          pagesPath._clinicAlias(clinicAlias).reservations._date(today).$url(),
        ),
      )

    if (isTopPage) {
      if (isToday) {
        window.location.reload()
      } else {
        // 予約一覧データがuseCalendarのstateに依存しており、URLの変更を検知できないためリロードする
        window.location.replace(
          toAsPath(
            pagesPath
              ._clinicAlias(clinicAlias)
              .reservations._date(today)
              .$url(),
          ),
        )
      }
    } else {
      redirectToToday()
    }
  }

  return (
    <Typography
      variant="h6"
      component="div"
      sx={{
        display: { xs: 'none', sm: 'block' },
        cursor: 'pointer',
      }}
      onClick={handleReloadOrPushToTop}
    >
      Atnd
    </Typography>
  )
}
