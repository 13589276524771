import { Button } from '@mui/material'
import Link from 'next/link'

import type { ButtonProps } from '@mui/material'
import type { LinkProps } from 'next/link'
import type { ReactNode } from 'react'

export const ButtonLink = ({
  buttonProps,
  children,
  fontSize,
  noDecoration = false,
  ...linkProps
}: LinkProps & {
  buttonProps?: ButtonProps
  width?: number | string
  noDecoration?: boolean
  fontSize?: number | string
  children?: ReactNode
}) => {
  const style = {
    textDecoration: noDecoration ? 'none' : 'underline',
    color: noDecoration ? 'inherit' : undefined,
    fontSize: fontSize,
  }
  return (
    <Link
      {...linkProps}
      onClick={(e) => {
        e.stopPropagation()
      }}
      style={style}
    >
      <Button {...buttonProps}>{children}</Button>
    </Link>
  )
}
