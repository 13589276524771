import { useParams } from 'next/navigation'
import { getCookie, useAtomValue, useSetAtom } from 'shared'

import { loginAtom, logoutAtom, staffAtom } from './staffAtom'

export const useStaff = () => {
  const params = useParams()
  const staff = useAtomValue(staffAtom)

  const login = useSetAtom(loginAtom)
  const logout = useSetAtom(logoutAtom)

  const handleLogin = async () => {
    const token = getCookie('token')
    const clinic_alias = params.clinicAlias?.toString()
    // const clinic_alias = getCookie('clinic_alias')

    if (token && clinic_alias) {
      login({ clinic_alias, token })
    }
  }

  return { handleLogin, staff, login, logout }
}
