'use client'

import { ThemeProvider } from '@emotion/react'
import { createTheme, CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { jaJP } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { SnackbarProvider, theme } from 'ui'

import type { FC, PropsWithChildren } from 'react'

const hospitalTheme = createTheme({
  ...theme,
  typography: {
    ...theme.typography,
    h1: { fontSize: '1.6rem' },
    h2: { fontSize: '1.3rem' },
    h3: { fontSize: '1.2rem' },
    h4: { fontSize: '1.1rem' },
    h5: { fontSize: '1.0rem' },
    h6: { fontSize: '0.9rem' },
    subtitle1: { fontSize: 14 },
    body1: { fontSize: 14 },
    body2: { fontSize: 12 },
  },
})

export const AppProviders: FC<PropsWithChildren> = ({
  children,
}): JSX.Element => {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={hospitalTheme}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={jaJP}
          dateFormats={{ monthAndYear: 'YYYY年 MM月' }}
          localeText={{
            previousMonth: '前月を表示', // < のツールチップ
            nextMonth: '次月を表示', // > のツールチップ
          }}
        >
          <SnackbarProvider>{children}</SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  )
}
