'use client'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useMemo } from 'react'
import { formatDate, setHospitalIdToCookie, toAsPath } from 'shared'
import { AuthGuard } from 'ui'

import { headerHeight } from '../../constants/size'
import { useStaff } from '../../stores/atoms/useStaff'
import { pagesPath } from '../../utils/$path'

import type { FC, PropsWithChildren } from 'react'

export const HospitalAuthGuard: FC<PropsWithChildren> = ({ children }) => {
  const { handleLogin, staff } = useStaff()

  const router = useRouter()
  const pathname = usePathname()

  const root = toAsPath(pagesPath.$url())

  const loginPath = pagesPath.login.$url().pathname

  const unauthorizedRoutes = useMemo(() => [loginPath], [loginPath])

  const redirectWhenAuthorizedPaths = useMemo(
    () => [root, loginPath],
    [loginPath, root],
  )

  const hospital = useMemo(() => {
    return staff?.hospitals[0]
  }, [staff?.hospitals])

  useEffect(() => {
    if (!staff) {
      handleLogin()
    }
  }, [staff, pathname, handleLogin])

  useEffect(() => {
    if (
      pathname &&
      staff &&
      hospital &&
      redirectWhenAuthorizedPaths.includes(pathname)
    ) {
      const appTop = toAsPath(
        pagesPath
          ._clinicAlias(hospital.url_alias)
          .reservations._date(formatDate(new Date()))
          .$url(),
      )

      setHospitalIdToCookie(hospital.url_alias, hospital.id)
      router.push(appTop)
    }
  }, [
    hospital,
    loginPath,
    pathname,
    redirectWhenAuthorizedPaths,
    router,
    staff,
    unauthorizedRoutes,
  ])

  return (
    <AuthGuard
      isAuthorized={!!staff}
      unauthorizedRoutes={unauthorizedRoutes}
      redirectWhenAuthorizedPaths={redirectWhenAuthorizedPaths}
      headerHeight={headerHeight}
      currentPath={pathname ?? ''}
      withFirebase={false}
    >
      {children}
    </AuthGuard>
  )
}
